<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		name: 'App',
		components: {

		},
    // created() {
    //   (function(m, ei, q, i, a, j, s) {
    //      m[i] = m[i] || function() {
    //       (m[i].a = m[i].a || []).push(arguments)
    //   };
    //   j = ei.createElement(q),
    //   s = ei.getElementsByTagName(q)[0];
    //   j.async = true;
    //   j.charset = 'UTF-8';
    //   j.setAttribute("data-json",JSON.stringify({ 
    //       id:570,
    //       token:'57a467a3fd69e62972c5dd7334e0d11e',
    //     image:'https://oss-huadong1.oss-cn-hangzhou.aliyuncs.com/wechatapp/customer_service/admin/2021/03/10/799617916_2021_03_10_19_17_43b3snd.jpg',
    //     nickName:'sadad'
    //   }));
      
    //   j.id=  "xiaokefu_js";
    //   j.src = 'https://pcjs.xiaokefu.com.cn/static/pc_Access/dist/pcAccess.js?version='+parseInt(new Date().getTime()/3600000);
    //   s.parentNode.insertBefore(j, s);
    //   })(window, document, 'script', '_xiaokefu');
    // }
	}
</script>

<style lang="scss">
	@import "./assets/scss/style.scss";
  @import "@/assets/scss/base";
  @import "~@/assets/scss/font";
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #555;
	}
	
</style>
